<template>
  <div class="CreateNewInvoice">
     <el-form label-position="left" :model="form" ref="form" :rules="rules" style="width:960px" label-width="120px">
       <el-row>{{form.companyName}}</el-row>
       <el-row>{{form.address}}</el-row>
       <el-row>Telephone:{{form.telephone}}</el-row>
     
     <el-form-item>
       <el-row type="flex" justify="center">
       <h4> INVOICE </h4>
       </el-row>
      </el-form-item>

      <el-form-item label="Project Name:" prop="projectName">
        <el-input v-model="form.projectName"></el-input>
      </el-form-item>
      
      <el-form-item label="Payment Item:" prop="paymentItem">
        <el-select v-model="form.payItem" placeholder="Select Item" style="width:100%" @change="onPayItemChange">
          <el-option
            v-for="item in payItems"
            :key="item.id"
            :label="item.payItem"
            :value="item.id">
          </el-option>
        </el-select>
       </el-form-item>
       

      <el-form-item label="INVOICE DATE:" label-position="left" label-width="120px" prop="invoiceDate">
        <el-col :span="10" inline="true">
          <el-date-picker
            v-model="form.invoiceDate"
            type="date"
            placeholder="Select Date"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
            style="width:100%">
          </el-date-picker>
        </el-col>

        <el-col :span="10" :offset="1">INVOICE NO: {{form.invoiceNo}}</el-col>
       
      
      </el-form-item>
      

      <el-form-item label="BILL TO:" prop="customer">
       <el-select v-model="form.customer" placeholder="Select Item" style="width:100%" @change="onCustomerChange">
          <el-option
            v-for="item in customers"
            :key="item.id"
            :label="item.customerName"
            :value="item.id">
          </el-option>
        </el-select>
       
        {{form.customerAddress}}
      </el-form-item>

      <el-form-item label="ATTN:">

              
        {{form.contactName}}
        <br/>
       
        {{form.email}}
        <br/>

        {{form.customerTelephone}}
      </el-form-item>  


  <el-form-item label="" label-position="left" label-width="120px" prop="currency">
  
   <el-select class="selectcurrency" v-model="form.currency" placeholder="Select Currency">
      <el-option
        v-for="item in currencys"
        :key="item.id"
        :label="item.currency"
        :value="item.currency">
      </el-option>
    </el-select>
     
    <el-button type="primary" size="medium" plain round @click="addItem">Add Item</el-button>
</el-form-item>

<el-form-item>
    <el-table
    border
    :data="form.invoiceItems" :summary-method="getSummaries" 
    show-summary style="width:100%; margin-top:20px">
    <el-table-column width= "300" label="Item">
     <template slot-scope="scope">
        <el-input v-model="scope.row.item"></el-input>
      </template>
    </el-table-column>

    <el-table-column width= "200" label="Unit Price">
      <template slot-scope="scope">
       <el-input v-model="scope.row.unitPrice" @input="calAmount(scope.row)"></el-input>
      </template>  
    </el-table-column>      
    
    <el-table-column width= "100" label="Quantity">
      <template slot-scope="scope">
       <el-input v-model="scope.row.quantity"  @input="calAmount(scope.row)"></el-input>
      </template>
    </el-table-column>

    <el-table-column label="Amount" prop="amount">
      <template slot-scope="scope">
        <el-input v-model="scope.row.amount"></el-input>
      </template>  
    </el-table-column>
  
  </el-table> 
</el-form-item>

  <el-form-item></el-form-item>


   <el-form-item class="bankInfo" label="ADVISING BANK FOR T/T:"></el-form-item>
   <el-form-item class="bankInfo" label="BANK NAME:">{{form.bankName}}</el-form-item>
   <el-form-item class="bankInfo" label="BRANCH"> {{form.branch}}</el-form-item>
   <el-form-item class="bankInfo" label="BRANCH ADDR:"> {{form.branchAdd}}</el-form-item>
   <el-form-item class="bankInfo" label="SWIFT CODE:"> {{form.swiftCode}}</el-form-item>
   <el-form-item class="bankInfo" label="A/C NO:"> {{form.acno}}</el-form-item> 
   <el-form-item class="bankInfo" label="NAME:"> {{form.acName}}</el-form-item> 
   <el-form-item class="bankInfo" label="NAME ADDS:"> {{form.acNameAdd}}</el-form-item>


  <el-form-item></el-form-item>
  
  <el-form-item>
    <el-col :span="2"><el-button v-if="id==0" type="primary" size="medium" plain round @click="submitForm('form')">Add</el-button></el-col>
    <el-col :span="2"><el-button v-if="id!=0" type="success" size="medium" plain round @click="modify">Modify</el-button></el-col>
    <el-col :span="2" class="download"><el-button v-if="id!=0" type="danger" size="medium" @click="handleClick()">Download</el-button></el-col>
  </el-form-item>
  

   </el-form>
  </div>
</template>

<script>
import { axiosInstance } from '@/api'
export default {
  data() {
    return {
      id:0,
      payItems:[],
      dialogFormVisible: false,
      form: {
        companyName:"",
        address:"",
        telephone:"",
        projectName:"",
        paymentItem:"",
        payItem:"",
        invoiceDate: '',
        customer:'',
        customerName:'',
        customerAddress:'',
        contactName:'',
        email:'',
        customerTelephone:'',
        invoiceItems:[],
        currency:'',
        bankName:'',
        branch:'',
        branchAdd:'',
        swiftCode:'',
        acno:'',
        acName:'',
        acNameAdd:'',
        total:'',
        invoiceNo:''
      },

      customers:[],
      currencys:[],

      rules: {
        
        paymentItem: [
          { required: true, message: 'Required', trigger: 'blur' },
        ],

        invoiceDate: [
          { required: true, message: 'Required', trigger: 'blur' },
        ],

        customer: [
          { required: true, message: 'Required', trigger: 'blur' },
        ],

        currency: [
          { required: true, message: 'Required', trigger: 'blur' },
        ],   
     
      },

      fullscreenLoading: false
 
    };
  },
  created() {
  
    this.loadPayItem();
    this.loadCustomer();
    this.loadCurrency();
    if(this.$route.params.invoiceId) {
      this.id = this.$route.params.invoiceId;
      axiosInstance.get("/InvoiceContent/"+this.id+"?expand=invoiceItems&expand=customer&expand=payItem").then(res=>{
        this.form = res.data;
        this.form.paymentItem = res.data.payItem.payItem;
        this.form.customerAddress = res.data.customer.address;
        this.form.contactName = res.data.customer.contactName;
        this.form.customerName = res.data.customer.customerName;
        this.form.email = res.data.customer.email;
        this.form.customerTelephone = res.data.customer.telephone;
        /** customer & payitem 的細項放上方(如以上), 主要項目放最後一行(如以下),程式才會啟動 */
        this.form.customer = this.form.customer.id;
        this.form.payItem = this.form.payItem.id;
        this.loadCompanyInfo();
      }) /** 以上的payItem,customer,companyInfo資訊是分別從payItem,customer,companyInfo取得,(expand=customer&expand=payItem&expand=companyInfo) 
      不從原本的invoice contaent取得的原因,是因為使用者要求當payItem,customer,companyInfo有修改時, 在invoice content可同時修改*/
    }else{
      this.loadCompanyInfo();
    }
  },
  methods:{

    modify(){
    this.fullscreenLoading = true;  
    axiosInstance.put("/InvoiceContent/"+this.id,
    this.form).then((response) => {
      this.$message({
              message: 'Success!!',
              type: 'success'
            })
          })
        .finally(()=>{
              this.fullscreenLoading = false;
          });
      },

    openFullScreen1() {
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
        }, 2000);
    },

    add(){
          this.fullscreenLoading = true;
          axiosInstance.post("/InvoiceContent",this.form)
          .then((response) => {
            this.form.invoiceNo = response.data.invoiceNo;
            for(const i in this.form.invoiceItems) {
              this.form.invoiceItems[i].id = response.data.invoiceItems[i].id
            }   
            this.id = response.data.id;
            this.$message({
              message: 'Success!!',
              type: 'success'
            })
          })
          .catch(error => {
              this.$message({
                message:'Error!!',
                type: 'error'
              })
          })
          .finally(()=>{
              this.fullscreenLoading = false;
          });
      },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.add()
        } else {
          alert('error submit!!');
          return false;
        }
      });

   },

    calAmount(row){
      if(isNaN(row.unitPrice) || isNaN(row.quantity)){
        row.amount = ''
      } else {
        row.amount = row.unitPrice * row.quantity;
      }
    },
    addItem(){
      this.form.invoiceItems.push({
        item: '',
        unitPrice: '',
        quantity: '',
        amount: '',
        quantity:''
      });
    },
    loadCompanyInfo() {
      axiosInstance.get("/companyInfo").then((response) => {
        this.form.companyName = response.data[0].companyName;
        this.form.address = response.data[0].address;
        this.form.telephone = response.data[0].telephone;
        this.form.bankName = response.data[0].bankName;
        this.form.branch = response.data[0].branch;
        this.form.branchAdd = response.data[0].branchAdd;
        this.form.swiftCode = response.data[0].swiftCode;
        this.form.acno = response.data[0].acno;
        this.form.acName = response.data[0].acName;
        this.form.acNameAdd = response.data[0].acNameAdd
        

      })
    },
    loadPayItem(){
      axiosInstance.get("/payItem").then((response) => {
        this.payItems = response.data;
      })
    },

    loadCustomer(){
         axiosInstance.get("/customer").then((response) => {
          this.customers = response.data;
          })
    },

     loadCurrency(){
         axiosInstance.get("/currency").then((response) => {
          this.currencys = response.data;
          })
    },

    onPayItemChange(selectedValue){
      this.form.paymentItem = this.payItems.find(item=>item.id==selectedValue).payItem
    },

    onCustomerChange(selectedValue){
      const customer = this.customers.find(item=>item.id==selectedValue);
      this.form.customerName = customer.customerName;
      this.form.customerAddress = customer.address;
      this.form.contactName = customer.contactName;
      this.form.email = customer.email;
      this.form.customerTelephone = customer.telephone
    },


    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
        sums[index] = '';
        return;
        }

        const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            this.form.total=sums[index];
            let n = sums[index];
            let str = n.toLocaleString("en-US");
            sums[index] = 'Total '+this.form.currency+" "+ str;
          } else {
            sums[index] = '';
          }
        });

      return sums;
    },

    handleClick() {
      axiosInstance.get(`/InvoiceContent/exportPdf/${this.id}`, { responseType: 'blob' })
        .then(response => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

          const link = document.createElement('a');

          link.href = downloadUrl;

          link.setAttribute('download', `${this.form.invoiceNo}.pdf`); //any other extension

          document.body.appendChild(link);

          link.click();

          link.remove();

        }).catch(console.error)
    },
 }
}

</script>

<style lang="scss" scoped>
.CreateNewInvoice{
  .selectcurrency {
    margin-right: 30px;
  };

 .el-form-item.bankInfo {
    margin-bottom: 0px;
    &:last-child {
      margin-bottom: 0;
    }
  };

  .download {
    margin-left: 30px;
  }

}


</style>